import React, { useState, useContext, useEffect } from "react";

import qs from "qs";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import "./login.scss";
import Logindivider from "../../components/LoginDivider/LoginDivider";
import axios from "../../utils/Axios";
import Register from "../../components/Register/Register";
import Loading from "../../components/Loading/Loading";
import { Context } from "../../utils/Context";
import Footer from "../../components/Footer/Footer";
import { CircularProgress } from "@mui/material";
import { baseUrl } from "../../utils/Base";

function Login() {
  const { t } = useTranslation(["components", "extras"]);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(false);
  const [show, setShow] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { lastVisitedForumToken } = state;
  const showPassword = () => {
    setShow(!show);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const tkn = localStorage.getItem("tkn");

    if (tkn && tkn !== "undefined") {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const loginHandler = async (data) => {
    const url = "/u";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(data),
      url,
    };
    try {
      setStart(true);
      const { data } = await axios(options);
      setStart(false);
      if (data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        navigate("/confirmation");
      }
      if (data?.tkn === "") {
        enqueueSnackbar(data?.msg, { variant: "error" });
        return;
      }
      if (data?.status === true && !data?.msg?.includes("check email")) {
        localStorage.setItem("tkn", data?.tkn);
        localStorage.setItem("userInfo", JSON.stringify(data?.usr));
        localStorage.setItem("lastSeen", data?.usr?.timelast);
        setTimeout(() => {
          dispatch({ type: "SHOW_TIPS" });
        }, 3000);
        if (
          localStorage.getItem("tipsCount") &&
          JSON.parse(localStorage?.getItem("tipsCount") < 4)
        ) {
          const newCount = JSON.parse(localStorage.getItem("tipsCount")) + 1;
          localStorage.setItem("tipsCount", JSON.stringify(newCount));
        }
        if (!localStorage.getItem("tipsCount")) {
          localStorage.setItem("tipsCount", JSON.stringify(1));
        }
        dispatch({ type: "USER_LOGIN", payload: data?.usr });

        lastVisitedForumToken === null
          ? navigate("/home")
          : navigate(`/forums/${lastVisitedForumToken}`);
      }
    } catch (err) {
      setStart(false);

      if (err?.code?.includes("NETWORK")) {
        enqueueSnackbar("No Internet Connection", { variant: "error" });
      }
      console.log(err);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="lgContainer">
          <HelmetProvider>
            <Helmet>
              <title>TruTab | {t("components:loginOrCreate")}</title>
              <meta name="description" content="TruTab login page"></meta>
            </Helmet>
          </HelmetProvider>
          <div className="lgLeft">
            <div className="lgLeftWrapper">
              <div className="lgLeftHeader">
                <img
                  src="/assets/images/nameLogo.png"
                  alt="name logo"
                  className="lNameLogo"
                />
                <div className="forUsersWrapperMobile">
                  <p className="forUsersTextBig">
                    {t("components:anonymousGroupChats")}
                  </p>
                </div>
                <div className="lgLogin">{t("components:login")}</div>
              </div>
              <form onSubmit={handleSubmit(loginHandler)} className="lgForm">
                <div className="lgInputContainer">
                  <input
                    placeholder={t("components:email")}
                    {...register("emailLg", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    })}
                  />
                  <img src="/assets/images/Email.svg" alt="" />
                </div>

                {(errors?.emailLg || errors?.emailLg?.type === "pattern") && (
                  <p className="lgError">
                    {errors?.emailLg
                      ? errors?.emailLg?.type === "pattern"
                        ? t("components:errEmailNotValid")
                        : t("components:errEmailRequired")
                      : ""}
                  </p>
                )}

                <div className="lgInputContainer">
                  <input
                    placeholder={t("components:password")}
                    type={show ? "text" : "password"}
                    {...register("passwordLg", { required: true })}
                  />
                  <img
                    src={
                      show
                        ? "/assets/images/Invisible.svg"
                        : "/assets/images/Eye.svg"
                    }
                    onClick={showPassword}
                    alt=""
                  />
                </div>
                {errors?.passwordLg && (
                  <p className="lgError">{t("components:errPassRequired")}</p>
                )}
                <div className="lgForgetPass">
                  <p>
                    <a href={`${baseUrl}/recovery`}>{t("components:forget")}</a>
                  </p>
                </div>

                <button type="submit" className="lgLoginBtn">
                  {t("components:login")}
                  {start && (
                    <CircularProgress size={15} className="btnProgress" />
                  )}
                </button>
              </form>

              <div className="lgDivider">
                <Logindivider />
              </div>
              <div className="lgCreateAcc">
                <button
                  className="lgRegisterBtn"
                  onClick={() => {
                    setShowRegister(true);
                  }}
                >
                  {t("extras:btnCreateAcc")}
                </button>
              </div>

              <div className="viewPublic">
                <p>{t("components:pubInterest")}</p>
                <Link to={"/public"}>{t("components:viewPub")}</Link>
              </div>
            </div>
          </div>
          <div className="lgRight">
            <div className="lgRightWrapper">
              <img src="/assets/images/amico1.svg" alt="" />
            </div>

            <div className="forUsersWrapper">
              <p className="forUsersTextBig">
                {t("components:anonymousGroupChats")}
              </p>
              <p className="forUsersTextSmall">{t("components:forUsers")}</p>
            </div>

            <div className="lgRightWrapperMobile">
              <div className="imgWrapperMobile">
                <img src="/assets/images/amico11.svg" alt="" />
              </div>
              <div
                className="forUsersWrapperMobile"
                style={{
                  marginLeft: "6%",
                  marginTop: "50px",
                }}
              >
                <p className="forUsersTextSmall">{t("components:forUsers")}</p>
              </div>
            </div>
          </div>
          {showRegister && (
            <Register
              showFunc={setShowRegister}
              showRegister={setShowRegister}
            />
          )}
        </div>
      )}
      <div className="secondRowWrapper">
        <div className="imgWrapperDesktop">
          <img src="/assets/images/amico2.svg" alt="" />
        </div>

        <div className="textWrapper">
          <p className="commonP">{t("components:toGetHonestFeedback")}</p>
          <div className="imgWrapperMobile">
            <img src="/assets/images/amico2b.svg" alt="" />
          </div>
          <p className="commonP">{t("components:companyFeedback")}</p>
        </div>
      </div>

      <div className="thirdRowWrapper">
        <div className="imgWrapperDesktop">
          <img src="/assets/images/amico3.svg" alt="" />
        </div>
        <div className="textWrapper">
          <p className="commonP">{t("components:schoolFeedback")}</p>
          <div className="imgWrapperMobile">
            <img src="/assets/images/amico3b.svg" alt="" />
          </div>
          <p className="commonP">{t("components:sportFeedback")}</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
