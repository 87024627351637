import moment from "moment";

//Time formater
export const FormatTime = (time) => {
  let timeArray = moment(time).fromNow().split(" ");

  if (timeArray[0] === "a" || timeArray[0] === "an") {
    if (timeArray[1] === "year") {
      return "1y";
    } else if (timeArray[1] === "month") {
      return "1mo";
    } else if (timeArray[1] === "day") {
      return "1d";
    } else if (timeArray[1] === "hour" || timeArray[2] === "hour") {
      return "1h";
    }
    if (timeArray[1] === "minute" || timeArray[2] === "minute") {
      return "1m";
    }
    if (timeArray[1] === "seconds" || timeArray[2] === "seconds") {
      return moment(time).fromNow();
    }
  }
  if (timeArray[1] === "minutes" && timeArray[0] !== "a") {
    return timeArray[0] + "m";
  } else if (timeArray[1] === "hours") {
    return timeArray[0] + "h";
  } else if (timeArray[1] === "days") {
    return timeArray[0] + "d";
  } else if (timeArray[1] === "months") {
    return timeArray[0] + "mo";
  } else if (timeArray[1] === "years") {
    return timeArray[0] + "y";
  } else {
    return moment(time).fromNow();
  }
};

export const canEditText = (time) => {
  const postTime = new Date(time).getTime();
  const currentTime = new Date().getTime();
  return currentTime - postTime <= 900000;
};

export const zeroPad = (num) => {
  if (num <= 9) {
    return `0${num}`;
  } else {
    return num;
  }
};

export const formatChildren = (number) => {
  if (number >= 1000000) {
      return (number / 1000000) + "M";
  } else if (number >= 1000) {
      return (number / 1000) + "k";
  } else {
      return number;
  }
}

