import {
  useContext,
  useRef,
  useReducer,
  useEffect,
  useState,
  useCallback,
} from "react";

import { useSnackbar } from "notistack";
import { FlagOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  ThemeProvider,
} from "@mui/material";

import "./TabPage.scss";
import TabSettings from "../TabSettings/TabSettings";
import ErrorPage from "../ErrorPage/ErrorPage";
import axios from "../../utils/Axios";
import TabPageTopicComment from "../TabPageTopicComment/TabPageTopicComent";
import Divider from "../Divider/Divider";
import { TooltipTheme, homeDialogTheme } from "../../utils/MuiThemes";
import { Context } from "../../utils/Context";
import { reducer, initialState } from "./State";
import CreateForum from "../TabSettings/CreateForum";
import CreateInvite from "../TabSettings/CreateInvite";

import { formatChildren, FormatTime } from "../../utils/TimeFormatter";
import { useClickOut, usePushNotification } from "../../utils/Effects";
import { baseUrl } from "../../utils/Base";
import { useViewport } from "../../utils/viewPort";
import NewPostBox from "../NewPostBox/NewPostBox";
import { useCopyTextToClipboard } from "../../utils/Copy";

function TabPage() {
  const { enqueueSnackbar } = useSnackbar();
  const modalRef = useRef();
  const checkRef = useRef();
  const tabContainerRef = useRef();
  const actionContainerTopicRef = useRef();
  const actionTopicMenuRef = useRef();
  const { state, dispatch } = useContext(Context);
  const [position, setPosition] = useState({});
  const [showCreateForum, setShowCreateForum] = useState(false);
  const [openDeleteForum, setOpenDeleteForum] = useState(false);
  const [ctgr, setCtgr] = useState("vstr");
  const { t } = useTranslation(["components", "extras"]);
  const copyTextToClipboard = useCopyTextToClipboard();
  const {
    apiLoading,
    loadText,
    modTab,
    verifyStatus,
    currentTabName,
    currentTabId,
    internalTabName,
    internalTabId,
    whichPageShouldOn,
    userInfo,
    forumAction,
    showCreateInvite,
  } = state;
  const [lState, lDispatch] = useReducer(reducer, initialState);
  const {
    load,
    descData,
    showModal,
    followStatus,
    showSettings,
    showSetTopic,
    showTopic,
    topicData,
    reload,
    errorPage,
    isTabDirector,
    forumDetails,
    showForumAction,
  } = lState;

  const { width } = useViewport();

  //fetchTabDataReload

  useClickOut({
    onState: showForumAction,
    mainRef: actionTopicMenuRef,
    subRef: actionContainerTopicRef,
    dispatch: lDispatch,
    dispatchType: "HIDE_FORUM_ACTION",
  });

 

  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };

  //Check container position
  const checkContainerPosition = useCallback(() => {
    setPosition(checkRef.current.getBoundingClientRect());
  }, []);

  useEffect(() => {
    const div = tabContainerRef.current;
    div.addEventListener("scroll", checkContainerPosition);
  }, [checkContainerPosition]);

  const fetchTabDataReload = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    setPosition({});
    try {
      lDispatch({ type: "START_RELOAD" });
      const { data } = await axios.get(
        `/u/tt?t=${currentTabId}&cntr=0`,
        config
      );
      if (data?.status === false) {
        lDispatch({ type: "SET_ERROR_PAGE" });
      } else {
        lDispatch({ type: "SET_TOPIC_DATA", payload: data?.temp_array });

        lDispatch({ type: "STOP_RELOAD" });
        if (data?.temp_array.length === 0) {
          dispatch({
            type: "NO_MORE_TOPIC",
            payload: t("components:noMoreTopic"),
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //fetchTabData
  const fetchTabData = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    setPosition({});
    try {
      dispatch({ type: "START_LOADING" });
      setShowCreateForum(false);
      lDispatch({ type: "HIDE_FORUM_ACTION" });
      const { data } = await axios.get(
        `/u/tt?t=${currentTabId}&cntr=${load}`,
        config
      );
      if (data?.status === false) {
        lDispatch({ type: "SET_ERROR_PAGE" });
      } else {
        lDispatch({ type: "SET_TOPIC_DATA", payload: data?.temp_array });
        dispatch({ type: "STOP_LOADING" });
        if (data?.temp_array.length === 0) {
          dispatch({
            type: "NO_MORE_TOPIC",
            payload: t("components:noMoreTopic"),
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //fetchDescriptionData

  const fetchDescData = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const { data } = await axios.get(`/u/t?t=${currentTabId}`, config);

    if (data?.status === false) {
      lDispatch({ type: "SET_ERROR_PAGE" });
    } else {
      lDispatch({ type: "SET_DESC_DATA", payload: data });
      dispatch({
        type: "SET_INTERNAL_TAB_ID",
        payload: data?.tab_desc?.tab_id,
      });
      dispatch({
        type: "SET_INTERNAL_TAB_NAME",
        payload: data?.tab_desc?.name,
      });
      if (
        data?.tabs_vrfd
          .map((element) => element.toLowerCase())
          ?.includes(data?.tab_desc?.name?.toLowerCase())
      ) {
        setCtgr("vrfd");
      }
      if (data?.fllwng === true) {
        lDispatch({
          type: "SET_FOLLOW_STATUS",
          payload: "unfollow",
        });
        if (data?.membs_dirs?.length === 0) {
          setCtgr("flwg");
        }
      } else {
        lDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
      }
    }
  };

  //Check forum details if there is any

  const fetchForumDetails = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const { data } = await axios.get(
      `/checkforum?tab=${currentTabName}`,
      config
    );

    lDispatch({ type: "SET_FORUM_DETAILS", payload: data });
  };

  const fetchTabDirectorStatus = async () => {
    const tkn = localStorage.getItem("tkn");
    const config = {
      headers: {
        authorization: `${tkn}`,
      },
    };
    const { data } = await axios.get(`/dxr?tab=${currentTabName}`, config);
    lDispatch({ type: "SET_TAB_DIRECTOR", payload: data?.status });
  };

  useEffect(() => {
    lDispatch({ type: "REFRESH" });
  }, [currentTabName]);

  useEffect(() => {
    fetchDescData();
    fetchTabDirectorStatus();
  }, [currentTabName, modTab, verifyStatus]);

  useEffect(() => {
    fetchForumDetails();
  }, [currentTabName, modTab, verifyStatus, forumAction]);

  useEffect(() => {
    fetchTabData();
  }, [load, currentTabName]);

  useEffect(() => {
    tabContainerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showModal &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        lDispatch({ type: "HIDE_MODAL" });
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal, modalRef]);

  const loadMore = () => {
    lDispatch({ type: "SET_LOAD" });
  };

  const followFunc = async (tabName) => {
    const { data } = await axios.get(`/follow?tab=${tabName}`, config);
    if (data.msg === "Now following.") {
      dispatch({ type: "FOLLOWING", payload: data.msg });
      lDispatch({
        type: "SET_FOLLOW_STATUS",
        payload: "unfollow",
      });
    } else if (data.msg === "Unfollowed.") {
      dispatch({ type: "NOT_FOLLOWING", payload: data.msg });
      lDispatch({ type: "SET_FOLLOW_STATUS", payload: "follow" });
    }

    enqueueSnackbar(data?.msg, {
      variant: data?.status === true ? "success" : "error",
    });
  };

  //Verify Function

  const verifyFunc = async () => {
    const { data } = await axios.get(
      `/ttv?email=${userInfo?.email}&tab=${internalTabName}`,
      config
    );
    enqueueSnackbar(data.msg, {
      variant: data?.status !== false ? "success" : "error",
    });

    if (data.status === true) {
      dispatch({ type: "VERIFIED" });
    }
  };

  //Resign Function

  const resignFunc = async () => {
    const { data } = await axios.get(`/resign?tabId=${internalTabId}`, config);
    if (data.status === true) {
      dispatch({ type: "VERIFIED" });
      enqueueSnackbar("You've left the Tab", { variant: "success" });
    }
    lDispatch({ type: "HIDE_MODAL" });
    dispatch({ type: "TAB_SETTING", payload: "leave" });
    dispatch({ type: "SHOW_TAB_PAGE", payload: null });
    dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
  };

  const handleShowTopic = (id, title) => {
    if (title === "...private post...") {
      enqueueSnackbar("This content is private to Tab's participants", {
        variant: "error",
      });
      return;
    }
    if (showTopic.includes(id)) {
      lDispatch({ type: "REMOVE_TOPIC", payload: id });
    } else {
      lDispatch({ type: "INCLUDE_TOPIC", payload: id });
    }
  };
  const reloadFunc = () => {
    lDispatch({ type: "REFRESH" });
    fetchTabDataReload();
    fetchDescData();
  };

  const handleCloseModal = () => {
    lDispatch({ type: "HIDE_MODAL" });
  };

  const handleDeleteForum = async () => {
    try {
      const { data } = await axios.get(
        `/endforum?tab=${internalTabName}`,
        config
      );
      if (data?.status) {
        enqueueSnackbar("Forum ended", {
          variant: "success",
        });
        setOpenDeleteForum(false);
        dispatch({ type: "SET_FORUM_ACTION" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  usePushNotification(ctgr, currentTabId?.toString());
  return (
    <div
      className="tabContainer"
      ref={tabContainerRef}
      style={{ display: whichPageShouldOn === "topic" && "none" }}
    >
      <>
        {errorPage ? (
          <ErrorPage />
        ) : (
          <>
            {reload ? (
              <div className="tpReload">
                <div>
                  {reload && <CircularProgress color="inherit" size={25} />}
                </div>
              </div>
            ) : descData?.usr_id === undefined ? (
              <div className="tpReload">
                <div>
                  <CircularProgress color="inherit" size={25} />
                </div>
              </div>
            ) : (
              <>
                <div className="tpNavCont">
                  <nav className="tpNav">
                    <IconButton
                      className="clickable"
                      style={{
                        display:
                          position?.top > 58 || position?.top === undefined
                            ? "flex"
                            : "none",
                      }}
                      onClick={() => {
                        dispatch({ type: "SHOW_TAB_PAGE", payload: null });
                        dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
                        dispatch({ type: "ON_PAGE", payload: "topic" });
                        dispatch({ type: "HIDE_SEARCH_POST" });
                      }}
                    >
                      <img
                        src="/assets/images/arrowBack.png"
                        alt=""
                        className="tpNavArrowBack"
                      />
                    </IconButton>

                    <div
                      onClick={reloadFunc}
                      className="tpNavTitle"
                      style={{
                        display:
                          position?.top > 58 || position?.top === undefined
                            ? "flex"
                            : "none",
                      }}
                    >
                      {descData?.tab_desc?.title}
                    </div>
                  </nav>
                </div>

                {descData?.tab_desc?.img !== (null || undefined) && (
                  <div className="tpBodyImg">
                    {descData?.tab_desc?.img === "" ? (
                      <img
                        src="/assets/images/newTabl.svg"
                        alt="attachment"
                        className="defaultImg"
                      />
                    ) : (
                      <img
                        src={`https://storage.googleapis.com/trutab/imgs/${descData?.tab_desc?.img}`}
                        alt=""
                        className="tabImg"
                      />
                    )}
                  </div>
                )}

                <div className="tpNameMemDirNoImg">
                  <div className="tpUniqueNameNoImg">
                    @{descData?.tab_desc?.name}
                  </div>

                  {descData?.membs_dirs.length === 0 ? (
                    ""
                  ) : (
                    <div className="tpMemberDirNoImg">
                      {descData?.membs_dirs[0]}{" "}
                      {descData?.membs_dirs[0] > 1
                        ? t("components:Members")
                        : t("components:Member")}{" "}
                      | {descData?.membs_dirs[1]}{" "}
                      {descData?.membs_dirs[1] > 1
                        ? t("components:Directors")
                        : t("components:Director")}
                    </div>
                  )}
                </div>

                <div className="tpDescription">{descData?.tab_desc?.desc}</div>
                <div
                  className={
                    position?.top < 58
                      ? "tpLeaveTopicCommentChangePosition"
                      : "tpLeaveTopicComment"
                  }
                  ref={checkRef}
                >
                  <div
                    style={{
                      display: position?.top < 58 ? "flex" : "none",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      className="clickable"
                      onClick={() => {
                        dispatch({ type: "SHOW_TAB_PAGE", payload: null });
                        dispatch({ type: "SHOW_TAB_PAGE_ID", payload: null });
                        dispatch({ type: "ON_PAGE", payload: "topic" });
                        dispatch({ type: "HIDE_SEARCH_POST" });
                      }}
                    >
                      <img
                        src="/assets/images/arrowBack.png"
                        alt=""
                        className="tpNavArrowBack"
                      />
                    </IconButton>

                    {width < 600 ? (
                      <div onClick={reloadFunc} className="tpNavTitle">
                        {descData?.tab_desc?.title?.substring(0, 17)}
                        {descData?.tab_desc?.title?.length > 17 && "..."}
                      </div>
                    ) : (
                      <div onClick={reloadFunc} className="tpNavTitle">
                        {descData?.tab_desc?.title?.substring(0, 40)}
                        {descData?.tab_desc?.title?.length > 40 && "..."}
                      </div>
                    )}
                  </div>
                  {descData?.usr_id ===
                    descData?.tab_desc?.usr_id?.toString() && (
                    <div className="spacer"></div>
                  )}

                  {descData?.tabs_vrfd.includes(descData?.tab_desc?.name)
                    ? descData?.usr_id !==
                        descData?.tab_desc?.usr_id?.toString() && (
                        <div
                          onClick={() => {
                            lDispatch({ type: "SHOW_MODAL" });
                          }}
                          className="tpLeave"
                          style={{
                            display:
                              position?.top > 58 || position?.top === undefined
                                ? "flex"
                                : "none",
                          }}
                        >
                          {(position?.top > 58 ||
                            position?.top === undefined) && (
                            <>
                              <img
                                src="/assets/images/leave.svg"
                                alt="leave"
                                style={{ width: "20px", height: "20px" }}
                              />
                              {t("extras:btnLeave")}
                            </>
                          )}
                        </div>
                      )
                    : descData?.usr_id !==
                        descData?.tab_desc?.usr_id?.toString() && (
                        <div
                          onClick={verifyFunc}
                          className="tpLeave"
                          style={{
                            display:
                              position?.top > 49 || position?.top === undefined
                                ? "flex"
                                : "none",
                          }}
                        >
                          {(position?.top > 58 ||
                            position?.top === undefined) && (
                            <>
                              {" "}
                              <img
                                src="/assets/images/leave.svg"
                                alt="leave"
                                style={{ width: "20px", height: "20px" }}
                              />
                              {t("extras:btnGO")}
                            </>
                          )}
                        </div>
                      )}
                  <section className="tabDesktopMenu">
                    <ThemeProvider theme={TooltipTheme}>
                      <div className="tpSettingTopic">
                        {descData?.usr_id !==
                          descData?.tab_desc?.usr_id?.toString() && (
                          <>
                            {descData?.tabs_vrfd.includes(
                              descData?.tab_desc?.name
                            ) ? (
                              <div
                                onClick={() => {
                                  lDispatch({ type: "SHOW_MODAL" });
                                }}
                                className="tpLeave"
                                style={{
                                  display: position?.top < 49 ? "flex" : "none",
                                }}
                              >
                                <img
                                  src={`/assets/images/${
                                    position?.top < 49 ? "leaveWhite" : "leave"
                                  }.svg`}
                                  alt="leave"
                                  style={{ width: "20px", height: "20px" }}
                                />
                                {(position?.top > 58 ||
                                  position?.top === undefined) &&
                                  t("extras:btnLeave")}
                              </div>
                            ) : (
                              <div
                                onClick={verifyFunc}
                                className="tpLeave"
                                style={{
                                  display: position?.top < 50 ? "flex" : "none",
                                }}
                              >
                                <img
                                  src={`/assets/images/${
                                    position?.top < 49 ? "leaveWhite" : "leave"
                                  }.svg`}
                                  alt="leave"
                                  style={{ width: "20px", height: "20px" }}
                                />
                                {(position?.top > 58 ||
                                  position?.top === undefined) &&
                                  t("extras:btnGO")}
                              </div>
                            )}
                          </>
                        )}

                        

                          <Tooltip title={t("Search Post")} arrow>
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`/assets/images/${
                                position?.top < 49 ? "searchWhite" : "search"
                              }.svg`}
                              alt="topic"
                              onClick={() => {
                                dispatch({
                                  type: "SHOW_SEARCH_POST_PANEL"
                                });
                              
                              }}
                            />
                          </Tooltip>
                       

                        {descData?.tabs_vrfd
                          .map((element) => element.toLowerCase())
                          ?.includes(
                            descData?.tab_desc?.name?.toLowerCase()
                          ) ? (
                          <Tooltip title={t("components:newTopic")} arrow>
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`/assets/images/${
                                position?.top < 49 ? "topicWhite" : "topic"
                              }.svg`}
                              alt="topic"
                              onClick={() => {
                                lDispatch({ type: "SET_SHOW_TOPIC" });
                                lDispatch({ type: "HIDE_SETTINGS" });
                                dispatch({
                                  type: "SET_SHOW_CREATE_INVITE",
                                  payload: false,
                                });
                                setShowCreateForum(false);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          ""
                        )}


                        {descData?.usr_id ===
                          descData?.tab_desc?.usr_id?.toString() && (
                          <Tooltip title="Share Invitation Link" arrow>
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={`/assets/images/${
                                position?.top < 49 ? "inviteWhite" : "invite"
                              }.svg`}
                              alt="invite"
                              onClick={() => {
                                dispatch({
                                  type: "SET_SHOW_CREATE_INVITE",
                                  payload: !showCreateInvite,
                                });
                                setShowCreateForum(false);
                                lDispatch({ type: "HIDE_SETTINGS" });
                                lDispatch({ type: "HIDE_TOPIC" });
                              }}
                            />
                          </Tooltip>
                        )}
                        {(descData?.usr_id ===
                          descData?.tab_desc?.usr_id?.toString() ||
                          isTabDirector) && (
                          <Tooltip title="Start Forum" arrow>
                            <img
                              src={`/assets/images/${
                                position?.top < 49
                                  ? "forumWhite"
                                  : forumDetails?.forum_start === null
                                  ? "forumGrey"
                                  : "forum"
                              }.svg`}
                              alt="start forum"
                              onClick={() => {
                                setShowCreateForum(!showCreateForum);
                                lDispatch({ type: "HIDE_SETTINGS" });
                                lDispatch({ type: "HIDE_TOPIC" });
                                dispatch({
                                  type: "SET_SHOW_CREATE_INVITE",
                                  payload: false,
                                });
                              }}
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Tooltip>
                        )}
                        {descData?.usr_id ===
                          descData?.tab_desc?.usr_id?.toString() && (
                          <Tooltip title={t("components:tbSettings")} arrow>
                            <img
                              src={`/assets/images/${
                                position?.top < 49 ? "settingWhite" : "setting"
                              }.svg`}
                              alt="tab settings"
                              onClick={() => {
                                lDispatch({ type: "SET_SHOW_SETTINGS" });
                                lDispatch({ type: "HIDE_TOPIC" });
                                dispatch({
                                  type: "SET_SHOW_CREATE_INVITE",
                                  payload: false,
                                });
                                setShowCreateForum(false);
                              }}
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </ThemeProvider>
                  </section>
                </div>

                {forumDetails?.forum_start !== null &&
                  forumDetails?.forum_start !== undefined &&
                  descData?.tabs_vrfd
                    .map((element) => element.toLowerCase())
                    ?.includes(descData?.tab_desc?.name?.toLowerCase()) && (
                    <section style={{ position: "relative" }}>
                      <div className="tpDivider">
                        <Divider width="calc(100% - 16px)" color="#908fc6" />
                      </div>
                      <div className="tpForumTimeDescAction">
                        <div className="tpForumTimeDesc">
                          <div className="tpForumTime">
                            <img src="/assets/images/forum.svg" alt="" />
                            <span>{FormatTime(forumDetails?.forum_start)}</span>
                          </div>
                          {forumDetails?.forum_desc}
                        </div>

                        {(descData?.usr_id ===
                          descData?.tab_desc?.usr_id?.toString() ||
                          isTabDirector) && (
                          <img
                            src="/assets/images/action.png"
                            alt=""
                            onClick={() =>
                              lDispatch({ type: "SHOW_FORUM_ACTION" })
                            }
                            ref={actionTopicMenuRef}
                          />
                        )}
                      </div>

                      <div
                        className={
                          showForumAction
                            ? "actionContainer"
                            : "actionContainer hideAction"
                        }
                        ref={actionContainerTopicRef}
                      >
                        <ThemeProvider theme={TooltipTheme}>
                          <Tooltip title="Copy Forum Link" arrow>
                            <img
                              src="/assets/images/copyBlue.svg"
                              alt=""
                              onClick={() =>
                                copyTextToClipboard(
                                  `${baseUrl}/forums/${forumDetails?.msg}`
                                )
                              }
                              className="copy"
                              style={{ width: "1.2rem", height: "1.2rem" }}
                            />
                          </Tooltip>
                          <Tooltip title="Download QR Image" arrow>
                            <a
                              href={`${baseUrl}/trutab_qrcode?q=${baseUrl}/forums/${forumDetails?.msg}&f=png`}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/assets/images/qr.svg"
                                alt=""
                                style={{ width: "1.2rem", height: "1.2rem" }}
                              />
                            </a>
                          </Tooltip>

                          {descData?.usr_id ===
                            descData?.tab_desc?.usr_id?.toString() && (
                            <Tooltip title="Delete Forum" arrow>
                              <img
                                src="/assets/images/recycle.svg"
                                alt=""
                                style={{ width: "1.2rem", height: "1.2rem" }}
                                onClick={() => setOpenDeleteForum(true)}
                              />
                            </Tooltip>
                          )}
                        </ThemeProvider>

                        <ThemeProvider theme={homeDialogTheme}>
                          <Dialog
                            open={openDeleteForum}
                            onClose={() => setOpenDeleteForum(false)}
                            maxWidth="xs"
                            PaperProps={{
                              style: {
                                boxShadow: "none",
                              },
                            }}
                          >
                            <div
                              className="tpDialogContainer"
                              style={{ backgroundColor: "#f8fcf8" }}
                            >
                              <p>{t("components:endForumWarn")}</p>

                              <div className="btn">
                                <button
                                  onClick={() => setOpenDeleteForum(false)}
                                  className="btnCancel"
                                >
                                  {t("extras:btnCancel")}
                                </button>
                                <button
                                  onClick={handleDeleteForum}
                                  className="btnExecute"
                                >
                                  {t("extras:btnDelete")}
                                </button>
                              </div>
                            </div>
                          </Dialog>
                        </ThemeProvider>
                      </div>
                    </section>
                  )}

                <div className="tpDivider">
                  <Divider width="calc(100% - 16px)" color="#908fc6" />
                </div>

                <div className="tpBody">
                  {showSetTopic && (
                    <NewPostBox
                      tabTitle={descData?.tab_desc?.title}
                      lDispatch={lDispatch}
                      topicTable={descData?.tab_desc?.name}
                      isTabDirector={isTabDirector}
                    />
                  )}

                  {showSettings && (
                    <div className="tpSettings">
                      <TabSettings
                        lDispatch={lDispatch}
                        isTabMember={descData?.tabs_vrfd.includes(
                          descData?.tab_desc?.name
                        )}
                        verify={verifyFunc}
                      />
                    </div>
                  )}
                  {showCreateForum && (
                    <div className="tpSettings">
                      <CreateForum controlState={showCreateForum} />
                    </div>
                  )}

                  {showCreateInvite && (
                    <div className="tpSettings">
                      <CreateInvite controlState={showCreateInvite} />
                    </div>
                  )}

                  <div className="tpTopicNum">
                    <div>
                      {descData?.total === 0 ? "" : descData?.total}{" "}
                      {descData?.total < 1
                        ? t("components:noTopic")
                        : descData?.total === 1
                        ? t("components:topic")
                        : t("components:topics")}
                    </div>
                    {descData?.total_public !== 0 && (
                      <div>
                        {descData?.total_public} {t("components:public")}
                      </div>
                    )}

                    {descData?.membs_dirs.length === 0 && (
                      <button
                        onClick={() => followFunc(descData?.tab_desc?.name)}
                      >
                        {followStatus === "unfollow"
                          ? t("components:unfollow")
                          : t("components:follow")}
                      </button>
                    )}
                  </div>
                </div>
                {topicData?.map((topic) => {
                  return (
                    <div className="tpTopic" key={topic?.comnt_id}>
                      <div
                        className="tpTitle"
                        onClick={() =>
                          handleShowTopic(topic?.comnt_id, topic?.content)
                        }
                      >
                        <span className="tpTitleHead">
                          {topic?.content?.slice(0, 215)}
                          {topic?.content?.length > 215 && "..."}
                        </span>
                        {topic?.settings[1] !== true &&
                          descData?.tabs_vrfd?.includes(
                            descData?.tab_desc?.name
                          ) && <FlagOutlined className="flag" />}
                        {topic?.children < 1 ? (
                          ""
                        ) : (
                          <div className="tpCommentNum">
                            <span>{formatChildren(topic?.children)}</span>
                            <img
                              src="/assets/images/comment.svg"
                              alt=""
                              className="tpCommentIcon"
                            />
                          </div>
                        )}
                      </div>
                      {showTopic?.includes(topic?.comnt_id) && (
                        <div className="tpCmt">
                          <TabPageTopicComment
                            topicLId={topic?.comnt_id}
                            tabLId={parseInt(topic?.tab_id)}
                            sDispatch={lDispatch}
                            tabTitle={descData?.tab_desc?.title}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="tpLoadMore">
                  <div>
                    {apiLoading && (
                      <CircularProgress color="inherit" size={25} />
                    )}
                  </div>
                  {descData?.total !== 0 && (
                    <button onClick={loadMore}>
                      {loadText === "Load more topics"
                        ? t("components:loadMoreTopics")
                        : loadText === "Loading"
                        ? t("components:loading")
                        : t("components:noMoreTopics")}
                    </button>
                  )}
                </div>

                <Dialog
                  open={showModal}
                  onClose={handleCloseModal}
                  maxWidth="xs"
                >
                  <div style={{ padding: "30px", background: "#f8f5fe" }}>
                    {" "}
                    <DialogTitle id="responsive-dialog-title">
                      <Typography style={{ fontSize: "1rem" }}>
                        {t("components:leaveTab")}
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <Typography>{t("components:sureLeaveTab")}</Typography>
                        <Typography>{t("components:tabLeaveWarn")}</Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <div className="tpBtnCont">
                        <Button onClick={resignFunc}>
                          {t("components:leave")}
                        </Button>
                        <Button
                          onClick={() => {
                            lDispatch({ type: "HIDE_MODAL" });
                          }}
                        >
                          {t("components:noLeave")}
                        </Button>
                      </div>
                    </DialogActions>
                    <DialogContentText>
                      <Typography>{t("components:unfollowToStop")}</Typography>
                      <Typography>{t("components:comeInAgain")}</Typography>
                    </DialogContentText>
                  </div>
                </Dialog>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default TabPage;
