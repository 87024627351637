import { createContext, useReducer } from "react";

export const Context = createContext();
export const initialState = {
  newUserEmail: "",
  userInfo: null,
  userTabs: null,
  created: [],
  verified: [],
  whichPageShouldOn: null,
  dispPics: localStorage.getItem("dispPics")
    ? `${localStorage.getItem("dispPics")}`
    : "/assets/images/photo1.png",
  apiLoading: false,
  settingLoading: false,
  tabsLoading: false,
  notificationsLoading: false,
  loadText: "Load more topics",
  searchResult: [],
  searchWord: "",
  searchProcess: false,
  searchPostProcess: false,
  topicId: "",
  followStatus: "",
  verifyStatus: false,
  newTabAlert: false,
  tabAcceptAlert: false,
  emailChange: false,
  tabSettingsDone: false,
  modTab: false,
  openTab: false,
  showProfile: false,
  showSetting: null,
  showAction: null,
  isDirector: false,
  userStatus: "",
  currentTabName: null,
  currentTabId: null,
  internalTabName: null,
  internalTabId: null,
  currentTopicId: null,
  currentTopicTabId: null,
  errorPageOn: false,
  page: "tab",
  previousPage: "",
  timeLine: "All Topics",
  showMore: [],
  tabSettings: [],
  tabsHeadCard: ["participate", "follow"],
  openAuthModal: false,
  forumAction: false,
  showSearchPanel: false,
  showSearchPostPanel: false,
  notifications: {},
  showNotifications: false,
  notificationCount: 0,
  lastVisitedForumToken: null,
  showCreateInvite: false,
  showTips: false,
  tipStep: 0,
  withinLimit: false,
  searchPostTab: "",
  showPostSearch: false,
  searchPostTabResult: [],
};

export function reducer(state, action) {
  switch (action.type) {
    case "NEW_USER_EMAIL":
      return { ...state, newUserEmail: action.payload };
    case "USER_LOGIN":
      return { ...state, userInfo: action.payload };
    case "USER_LOGOUT":
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("tkn");
      localStorage.removeItem("userInfo");
      return { ...initialState };
    case "SET_USER_TABS":
      return { ...state, userTabs: action.payload };
    case "SET_USER_TABS_CREATED":
      return { ...state, created: action.payload };
    case "SET_USER_TABS_VERIFIED":
      return { ...state, verified: action.payload };
    case "SET_DP":
      return {
        ...state,
        dispPics: action.payload,
      };
    case "START_LOADING":
      return {
        ...state,
        apiLoading: true,
        loadText: "Loading",
      };
    case "STOP_LOADING":
      return {
        ...state,
        apiLoading: false,
        loadText: "Load more topics",
      };
    case "START_SETTING_LOADING":
      return {
        ...state,
        settingLoading: true,
      };
    case "STOP_SETTING_LOADING":
      return {
        ...state,
        settingLoading: false,
      };

    case "START_TABS_LOADING":
      return {
        ...state,
        tabsLoading: true,
      };
    case "STOP_TABS_LOADING":
      return {
        ...state,
        tabsLoading: false,
      };
    case "START_NOTIFICATIONS_LOADING":
      return {
        ...state,
        notificationsLoading: true,
      };
    case "STOP_NOTIFICATIONS_LOADING":
      return {
        ...state,
        notificationsLoading: false,
      };
    case "SHOW_NOTIFICATIONS":
      return {
        ...state,
        showNotifications: true,
      };
    case "HIDE_NOTIFICATIONS":
      return { ...state, showNotifications: false };
    case "NO_MORE_TOPIC":
      return {
        ...state,
        apiLoading: false,
        loadText: "No more topic",
      };

    case "SHOW_MORE":
      return { ...state, showMore: [...state.showMore, action.payload] };

    case "SHOW_LESS":
      const removed = action.payload;
      const filtered = state.showMore?.filter((rest) => rest !== removed);
      return { ...state, showMore: filtered };

    case "START_SEARCH":
      return {
        ...state,
        searchProcess: true,
      };
    case "STOP_SEARCH":
      return {
        ...state,
        searchProcess: false,
      };

      case "START_SEARCH_POST":
        return {
          ...state,
          searchPostProcess: true,
        };
      case "STOP_SEARCH_POST":
        return {
          ...state,
          searchPostProcess: false,
        };

    case "SET_SEARCH_RESULT":
      return {
        ...state,
        searchResult: action.payload,
      };
    case "CLEAR_SEARCH_RESULT":
      return {
        ...state,
        searchResult: [],
      };
    case "SET_SEARCH_WORD":
      return {
        ...state,
        searchWord: action.payload,
      };
    case "CLEAR_SEARCH_WORD":
      return {
        ...state,
        searchWord: "",
      };
    case "SET_CURRENT_TAB_ID":
      return {
        ...state,
        currentTabId: action.payload,
      };
    case "SET_CURRENT_TAB_NAME":
      return {
        ...state,
        currentTabName: action.payload,
      };
    case "SET_INTERNAL_TAB_ID":
      return {
        ...state,
        internalTabId: action.payload,
      };
    case "SET_INTERNAL_TAB_NAME":
      return {
        ...state,
        internalTabName: action.payload,
      };
    case "SET_TOPICID":
      return {
        ...state,
        topicId: action.payload,
      };
    case "FOLLOWING":
      return {
        ...state,
        followStatus: action.payload,
      };
    case "NOT_FOLLOWING":
      return {
        ...state,
        followStatus: action.payload,
      };

    case "ERROR_PAGE_ON":
      return {
        ...state,
        errorPageOn: action.payload,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
      };
    case "SET_NOTIFICATION_COUNT":
      return {
        ...state,
        notificationCount: action.payload,
      };
    case "SET_FORUM_ACTION":
      return {
        ...state,
        forumAction: !state.forumAction,
      };
    case "TAB_SETTING":
      const toCheck = action.payload;
      const remove = state.tabSettings?.filter((s) => s !== toCheck);
      if (state.tabSettings?.includes(toCheck)) {
        return { ...state, tabSettings: remove };
      } else {
        return { ...state, tabSettings: [...state.tabSettings, toCheck] };
      }

    case "TABS_HEAD_CARD":
      const headTag = action.payload;
      const filteredHead = state.tabsHeadCard?.filter((s) => s !== headTag);
      if (state.tabsHeadCard?.includes(headTag)) {
        return { ...state, tabsHeadCard: filteredHead };
      } else {
        return { ...state, tabsHeadCard: [...state.tabsHeadCard, headTag] };
      }

    case "VERIFIED":
      return {
        ...state,
        verifyStatus: !state.verifyStatus,
      };
    case "NEW_TAB":
      return {
        ...state,
        newTabAlert: !state.newTabAlert,
      };
    case "DELETE_TAB":
      const tabToDel = action.payload;
      const filteredCreated = state.created?.filter(
        (cd) => cd?.tab_id !== tabToDel
      );
      const filteredVerified = state.verified?.filter(
        (cd) => cd?.tab_id !== tabToDel
      );

      return {
        ...state,
        created: filteredCreated,
        verified: filteredVerified,
      };
    case "TAB_ACCEPT":
      return {
        ...state,
        tabAcceptAlert: !state.tabAcceptAlert,
      };
    case "TAB_MODIFIED":
      return {
        ...state,
        modTab: !state.modTab,
      };
    case "TAB_SETTINGS_DONE":
      const tabName = action.payload.tabName;
      return {
        ...state,
        currentTabName: tabName,
        tabSettingsDone: !state.tabSettingsDone,
      };
    case "SHOW_SETTING":
      return { ...state, showSetting: action.payload };
    case "HIDE_SETTING":
      return { ...state, showSetting: null };
    case "SET_SHOW_CREATE_INVITE":
      return { ...state, showCreateInvite: action.payload };

    case "SHOW_SEARCH_PANEL":
      return { ...state, showSearchPanel: true };
    case "HIDE_SEARCH_PANEL":
      return { ...state, showSearchPanel: false };

      case "SHOW_SEARCH_POST_PANEL":
        return { ...state, showSearchPostPanel: true };
      case "HIDE_SEARCH_POST_PANEL":
        return { ...state, showSearchPostPanel: false };
    case "SHOW_ACTION":
      return { ...state, showAction: action.payload };
    case "HIDE_ACTION":
      return { ...state, showAction: null };

    case "SHOW_TIPS":
      return { ...state, showTips: true };
    case "HIDE_TIPS":
      return { ...state, showTips: false };
    case "SET_TIP_STEP":
      return {
        ...state,
        tipStep: action.payload,
      };
    case "SET_WITHIN_LIMIT":
      return {
        ...state,
        withinLimit: action.payload,
      };
    case "IS_DIRECTOR":
      return { ...state, isDirector: action.payload };
    case "SET_USER_STATUS":
      return { ...state, userStatus: action.payload };
    case "EMAIL_CHANGE_ALERT":
      return { ...state, emailChange: !state.emailChange };
    case "SHOW_TAB_PAGE":
      return { ...state, currentTabName: action.payload };
    case "SHOW_TAB_PAGE_ID":
      return { ...state, currentTabId: action.payload };
    case "SHOW_TOPIC_PAGE":
      return {
        ...state,
        currentTopicId: action.payload?.comntId,
        currentTopicTabId: action.payload?.tabId,
      };

    case "ON_PAGE":
      return { ...state, whichPageShouldOn: action.payload };

    case "SET_PAGE_TO_HOME":
      return { ...state, page: "home", showProfile: true };
    case "SET_PREVIOUS_PAGE":
      return { ...state, previousPage: action.payload };
    case "SET_LAST_VISITED_FORUM_TOKEN":
      return { ...state, lastVisitedForumToken: action.payload };
    case "OPEN_AUTH_MODAL":
      return { ...state, openAuthModal: true };
    case "CLOSE_AUTH_MODAL":
      return { ...state, openAuthModal: false };
    case "SET_PAGE_TO_TAB":
      return {
        ...state,
        page: "tab",
        showProfile: true,
        openTab: !state.openTab,
      };
    case "SET_PAGE_TO_SETTINGS":
      return {
        ...state,
        page: "settings",
        showProfile: true,
        openTab: !state.openTab,
      };
    case "SET_PAGE_TO_SETTING":
      return { ...state, showProfile: true };
    case "SHOW_PROFILE":
      return { ...state, showProfile: !state.showProfile };

    case "SET_TIME_LINE":
      return { ...state, timeLine: action.payload };

    case "SET_SEARCH_POST_TAB":
      return {
        ...state,
        searchPostTab: action.payload,
      };
    case "CLEAR_SEARCH_POST_TAB":
      return {
        ...state,
        searchPostTab: action.payload,
      };
    case "SHOW_POST_TAB_SEARCH":
      return {
        ...state,
        showPostSearch: true,
      };
    case "SEARCH_POST_TAB_RESULT":
      return {
        ...state,
        searchPostTabResult: action.payload,
      };
    case "CLEAR_POST_TAB_RESULT":
      return {
        ...state,
        searchPostTabResult: [],
      };

    default:
      return state;
  }
}

export function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}
