import { useContext, useReducer, useRef } from "react";

import qs from "qs";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CircularProgress, ThemeProvider, Tooltip } from "@mui/material";
import { Circle } from "@mui/icons-material";

import "./TabPageTopicComment.scss";
import axios from "../../utils/Axios";
import TopicComment from "../TopicComment/TopicComment";
import EditPostTopic from "../EditPostTopic/EditPostTopic";
import ImageSlider from "../ImageSlider/ImageSlider";
import { UidGenerator } from "../../utils/UidGenerator";
import { useSnackbar } from "notistack";
import { Context } from "../../utils/Context";
import { AnonContext } from "../AnonymousTabs/State";
import { PubContext } from "../PublicTabs/State";
import { PriContext } from "../PrivateTabs/State";
import { PerContext } from "../PersonalTopics/State";
import { FgContext } from "../Flagged/State";
import { LkContext } from "../Liked/State";
import { SvContext } from "../Saved/State";
import {
  ActionMenuIcon,
  FlagIcon,
  LikeIcon,
  SaveIcon,
  SettingsIcon,
} from "../Icons/Icons";
import { initialState, reducer } from "./State";
import { useClickOut } from "../../utils/Effects";
import { FormatTime, canEditText, formatChildren } from "../../utils/TimeFormatter";
import { commentDialogTheme } from "../../utils/MuiThemes";

import { Dialog } from "@mui/material";
import { FetchTabTopics } from "../../utils/Effects";
import { TopicTooltipTheme } from "../../utils/MuiThemes";
import { RenderContent } from "../../utils/RenderContent";
import { useLocation, useNavigate } from "react-router-dom";

function TabPageTopicComment({ topicLId, tabLId, sDispatch, tabTitle }) {
  const location = useLocation();
  const splitLocation = location.pathname.split("/");
  const navigate = useNavigate();
  const actionMenuRef = useRef();
  const actionContainerRef = useRef();
  const { t } = useTranslation(["components, extras"]);
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(Context);
  const { anonDispatch } = useContext(AnonContext);
  const { pubDispatch } = useContext(PubContext);
  const { priDispatch } = useContext(PriContext);
  const { perDispatch } = useContext(PerContext);
  const { fgDispatch } = useContext(FgContext);
  const { lkDispatch } = useContext(LkContext);
  const { svDispatch } = useContext(SvContext);
  const {
    userInfo,
    showSetting,
    showAction,
    settingLoading,
    topicId,
    verified,
    showMore,
  } = state;
  const [lState, lDispatch] = useReducer(reducer, initialState);
  const tkn = localStorage.getItem("tkn");
  const config = {
    headers: {
      authorization: `${tkn}`,
    },
  };
  const {
    showComment,
    parent,
    topicData,
    loading,
    load,
    posting,
    showModal,
    open,
    newComment,
  } = lState;

  useClickOut({
    onState: showAction,
    mainRef: actionMenuRef,
    subRef: actionContainerRef,
    dispatch: dispatch,
    dispatchType: "HIDE_ACTION",
  });

  const { register, handleSubmit } = useForm();

  const handleClickOpen = (id) => {
    lDispatch({ type: "SHOW_MODAL", payload: id });
    lDispatch({ type: "OPEN_MODAL" });
  };

  const handleClose = () => {
    lDispatch({ type: "CLOSE_MODAL" });
  };

  //Fetch data for tab page topics
  FetchTabTopics(load, lDispatch, topicLId, tabLId);

  const handleDeleteTopic = async (id) => {
    const { data } = await axios.post(
      "/delc",
      {
        id: showModal,
        type: "c",
      },
      config
    );
    if (data?.status === true) {
      lDispatch({ type: "DELETE_TOPIC", payload: showModal });
      if (typeof anonDispatch === "function") {
        anonDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof pubDispatch === "function") {
        pubDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof priDispatch === "function") {
        priDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof perDispatch === "function") {
        perDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof fgDispatch === "function") {
        fgDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof lkDispatch === "function") {
        lkDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      if (typeof svDispatch === "function") {
        svDispatch({ type: "DELETE_TOPIC", payload: showModal });
      }
      dispatch({ type: "TOPIC_DELETE_TOGGLE" });
      sDispatch({ type: "UPDATE_TAB_TOPIC", payload: id });
      enqueueSnackbar(data.msg, { variant: "success" });
      handleClose();
    } else {
      enqueueSnackbar(data.msg, { variant: "error" });
      handleClose();
    }
  };

  const handleShowComment = (id, parent) => {
    if (showComment !== id) {
      lDispatch({ type: "SHOW_COMMENT", payload: id });
      lDispatch({ type: "PARENT", payload: parent });
    } else {
      lDispatch({ type: "SHOW_COMMENT", payload: null });
      lDispatch({ type: "PARENT", payload: null });
    }
  };
  const handleShowTopicTab = (comntId, tabId) => {
    dispatch({
      type: "SHOW_TOPIC_PAGE",
      payload: { comntId, tabId },
    });
    dispatch({
      type: "ON_PAGE",
      payload: "topic",
    });
  };

  const handleShowMore = (id) => {
    if (showMore?.includes(id)) {
      dispatch({ type: "SHOW_LESS", payload: id });
    } else {
      dispatch({ type: "SHOW_MORE", payload: id });
    }
  };
  const postComment = async ({ newCmntTxt }) => {
    const inputData = {
      text: newCmntTxt,
      tab: parent,
      post_id: showComment,
    };
    const url = "/newc";

    const options = {
      method: "POST",
      headers: {
        authorization: tkn,
        "content-type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(inputData),
      url,
    };
    lDispatch({ type: "POSTING" });
    const { data } = await axios(options);
    lDispatch({ type: "POSTING_DONE" });
    lDispatch({ type: "SHOW_COMMENT", payload: null });
    lDispatch({ type: "PARENT", payload: null });
    lDispatch({ type: "NEW_COMMENT", payload: data });
    lDispatch({
      type: "UPDATE_COMMENT",
      payload: {
        pId: parseInt(data?.new_c?.parent),
        newCount: data?.new_count,
      },
    });
    if (typeof anonDispatch === "function") {
      anonDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      anonDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof pubDispatch === "function") {
      pubDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      pubDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof priDispatch === "function") {
      priDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      priDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof perDispatch === "function") {
      perDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      perDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof fgDispatch === "function") {
      fgDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      fgDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof lkDispatch === "function") {
      lkDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      lkDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof svDispatch === "function") {
      svDispatch({
        type: "UPDATE_COMMENT",
        payload: {
          pId: parseInt(data?.new_c?.parent),
          newCount: data?.new_count,
        },
      });
      svDispatch({ type: "NEW_COMMENT", payload: data });
    }
    if (typeof sDispatch === "function") {
      sDispatch({
        type: "UPDATE_TOPIC_COMMENT_NO",
        payload: { pId: topicLId, newCount: data?.new_count },
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="tptProgress">
          <CircularProgress color="inherit" size={25} />
        </div>
      ) : (
        topicData?.map((topicData) => {
          return (
            <div className="tptContainer" key={topicData?.comnt_id}>
              <div className="subContainer">
                <div className="firstBlock">
                  <button className="topicTitle">{tabTitle}</button>
                  <small className="topicParent">@{topicData?.parent}</small>
                </div>
                <div className="secondBlock">
                  <div className="topicDescription">
                    <span>
                      <span
                        onClick={() => {
                          splitLocation[1] === "tabs"
                            ? navigate(
                                `/topics/${topicData?.comnt_id}/${parseInt(
                                  topicData?.tab_id
                                )}`
                              )
                            : handleShowTopicTab(
                                topicData?.comnt_id,
                                parseInt(topicData?.tab_id)
                              );
                        }}

                        // onClick={() => handleShowTopicTab(topicData?.comnt_id)}
                      >
                        {showMore?.includes(topicData?.comnt_id)
                          ? RenderContent(
                              topicData?.content?.split(/\r?\n/),
                              <span className="identifier">
                                <ThemeProvider theme={TopicTooltipTheme}>
                                  <Tooltip
                                    title={t("components:identifierTooltip")}
                                  >
                                    <span>
                                      -
                                      {UidGenerator(
                                        topicData?.time,
                                        topicData.usr_id
                                      )}
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                              </span>,
                              "topic"
                            )
                          : RenderContent(
                              topicData?.content
                                ?.substring(0, 200)
                                ?.split(/\r?\n/),
                              <span className="identifier">
                                <ThemeProvider theme={TopicTooltipTheme}>
                                  <Tooltip
                                    title={t("components:identifierTooltip")}
                                  >
                                    <span>
                                      -
                                      {UidGenerator(
                                        topicData?.time,
                                        topicData?.usr_id
                                      )}
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                              </span>,
                              "topic"
                            )}
                      </span>
                      {topicData?.content?.length > 200 && (
                        <button
                          onClick={() => {
                            handleShowMore(topicData?.comnt_id);
                          }}
                        >
                          {showMore?.includes(topicData?.comnt_id) ? (
                            <span style={{ marginLeft: "2px" }}>
                              {t("extras:btnSL")}
                            </span>
                          ) : (
                            t("extras:btnSM")
                          )}
                        </button>
                      )}
                    </span>
                  </div>
                </div>
                <div className="thirdBlock">
                  <div className="status">
                    <div>
                      {topicData?.settings[0] === true
                        ? t("components:publicT")
                        : t("components:privateT")}
                      <Circle className="dot" />
                    </div>
                    <div>
                      {topicData?.edited === true && (
                        <>
                          {t("components:edited")} <Circle className="dot" />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="small">{FormatTime(topicData?.time)}</div>
                </div>

                <div className="fourthBlock">
                  <div className="fourthBlockLeft">
                    <LikeIcon
                      postId={topicData?.comnt_id}
                      like={topicData?.likes}
                      lkSt={topicData?.like_st}
                    />
                    <span className="badgeIcon">
                      <SaveIcon
                        postId={topicData?.comnt_id}
                        saved={topicData?.save_st}
                      />
                    </span>
                    {verified?.some(
                      (tab) => tab?.name === topicData?.parent
                    ) && (
                      <>
                        <div
                          className="badgeCont"
                          onClick={() =>
                            handleShowComment(
                              topicData?.comnt_id,
                              topicData?.parent
                            )
                          }
                        >
                          <img
                            src="/assets/images/comment.svg"
                            alt=""
                            className="badgeIicon"
                          />{" "}
                            <span className="badgeContText">
                              {topicData?.children === 0
                                ? ""
                                : formatChildren(topicData?.children)}
                            </span>
                        </div>

                        <span className="badgeIcon">
                          <FlagIcon
                            postId={topicData?.comnt_id}
                            flagged={topicData?.settings[1]}
                          />
                        </span>
                      </>
                    )}
                  </div>

                  <div className="fourthBlockRight">
                    <ActionMenuIcon
                      userId={topicData?.usr_id}
                      parent={topicData?.parent}
                    >
                      <div
                        className="actionMenu"
                        onClick={() =>
                          dispatch({
                            type: "SHOW_ACTION",
                            payload: topicData?.comnt_id,
                          })
                        }
                        ref={actionMenuRef}
                      >
                        <img src="/assets/images/action.png" alt="" />
                      </div>
                    </ActionMenuIcon>

                    <div
                      className={
                        showAction === topicData?.comnt_id
                          ? "actionContainer"
                          : "actionContainer hideAction"
                      }
                      ref={actionContainerRef}
                    >
                      {topicData?.usr_id === userInfo?.idd && (
                        <img
                          src="/assets/images/recycle.svg"
                          alt=""
                          className="icon"
                          onClick={() => handleClickOpen(topicData?.comnt_id)}
                        />
                      )}
                      <ThemeProvider theme={commentDialogTheme}>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          maxWidth="xs"
                          PaperProps={{
                            style: {
                              boxShadow: "none",
                            },
                          }}
                        >
                          <div className="tpDialogContainer">
                            <p>{t("components:sureDeleteTopic")}</p>
                            <p> {t("components:noUndo")}</p>
                            <div className="btn">
                              <button
                                onClick={handleClose}
                                className="btnCancel"
                              >
                                {t("extras:btnCancel")}
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteTopic(topicData?.comnt_id)
                                }
                                className="btnExecute"
                              >
                                {t("extras:btnDelete")}
                              </button>
                            </div>
                          </div>
                        </Dialog>
                      </ThemeProvider>
                      <SettingsIcon
                        cmntId={topicData?.comnt_id}
                        usrId={topicData?.usr_id}
                        parent={topicData?.parent}
                        lDispatch={lDispatch}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {showComment === topicData?.comnt_id && (
                <form onSubmit={handleSubmit(postComment)}>
                  <div className="postComment">
                    <textarea
                      {...register("newCmntTxt")}
                      cols="30"
                      rows="4"
                      placeholder={t("components:pHCommentBox")}
                    ></textarea>

                    <div className="btn">
                      <button>
                        {posting ? (
                          <>
                            {t("extras:btnPosting")}
                            <CircularProgress
                              size={15}
                              className="btnProgress"
                            />
                          </>
                        ) : (
                          t("extras:btnPost")
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              )}
              {topicData?.attchmnts !== (null || undefined) &&
                topicData?.attchmnts?.length >= 1 && (
                  <ImageSlider slides={topicData?.attchmnts} />
                )}
              <div className="loadmore">
                {settingLoading && topicId === topicData?.comnt_id && (
                  <CircularProgress color="inherit" size={25} />
                )}
              </div>
              {showSetting === topicData?.comnt_id && (
                <EditPostTopic
                  title={
                    userInfo?.idd === topicData?.usr_id &&
                    canEditText(topicData?.time)
                      ? t("components:editTopic")
                      : t("components:editVisibility")
                  }
                  func={t("extras:btnSaveEdit")}
                  noAtt
                  editInx={topicData?.comnt_id}
                  topicTable={topicData?.parent}
                  usrId={topicData?.usr_id}
                  lDispatch={lDispatch}
                  spDispatch={sDispatch}
                  defaultText={topicData?.content}
                  time={topicData?.time}
                />
              )}
              {topicData?.children !== 0 && (
                <TopicComment
                  comntId={topicData?.comnt_id}
                  pChild={topicData?.children}
                  pDispatch={lDispatch}
                  tDispatch={sDispatch}
                  newSibling={newComment}
                />
              )}
            </div>
          );
        })
      )}
    </>
  );
}

export default TabPageTopicComment;
