import { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { ProgressTheme } from "../../utils/MuiThemes";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import "./Loading.scss";
function Loading() {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation(["components"]);

  useEffect(() => {
    const updateProgress = () => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return 100; // Stop at 100
        }
        const diff = Math.random() * 20; // Smaller increments for smoother progress
        return Math.min(oldProgress + diff, 100);
      });
    };

    const timer = setTimeout(() => {
      updateProgress();
      if (progress < 100) {
        setTimeout(updateProgress, 500); // Continue updating until it reaches 100
      }
    }, 300);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [progress]);

  return (
    <div className="loadContainer">
      <img
        src="/assets/images/logo64small.png"
        alt="logo"
        className="loadLogo"
      />
      <img src="/assets/images/name.png" alt="name" className="loadName" />

      <p>{t("feedbackInter")}</p>
      <ThemeProvider theme={ProgressTheme}>
        <div>
          <LinearProgress
            className="loadLoading"
            variant="determinate"
            value={progress}
          />
          <p className="loadLoadingText">{t("loading")}</p>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default Loading;
